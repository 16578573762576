import React, { useEffect, useState } from 'react';
import { createEvent, getAllEvents, updateEvent } from '../../services/EventService';
import { useNavigate } from 'react-router-dom';
import {getUserContacts} from "../../services/ContactService";

const EventForm = ({ event }) => {
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [emailMessage, setEmailMessage] = useState("");
    const [gif, setGif] = useState(null);

    const navigate = useNavigate();

    // Prefill form if editing an event
    useEffect(() => {
        if (event) {
            setEventName(event.eventName || '');
            setEventDate(event.eventDate || '');
            setEmailMessage(event.emailMessage || '');
            // Pre-select the contacts associated with the event
            setSelectedContacts(event.contacts ? event.contacts.map(contact => contact.id) : []);
        }
    }, [event]);

    // Fetch user's contacts on component mount
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await getUserContacts();
                setContacts(response.data);
                console.log('contact', contacts);
            } catch (error) {
                console.error("Error fetching contacts:", error);
            }
        };

        fetchContacts();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('selected contacts', selectedContacts)
        // Create FormData object to handle file and other data
        const formData = new FormData();
        const eventDTO = {
            eventName,
            eventDate,
            emailMessage,
            contactIds: selectedContacts,
        };

        formData.append("eventDTO", new Blob([JSON.stringify(eventDTO)], { type: "application/json" }));
        if (gif) {
            formData.append("gif", gif);
        }

        try {
            if (event) {
                await updateEvent(event.id, formData);  // Update the event
                alert('Event updated successfully!');
            } else {
                await createEvent(formData);  // Use EventService to post the new event
                alert('Event created successfully!');
                setEventName('');  // Reset form fields after submission
                setEventDate('');
                setEmailMessage('');
                setGif(null);
            }
            navigate('/events');  // Redirect to the event list page
        } catch (error) {
            alert('Error creating event. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="text-center">
            <label className="form-label">
                Event Name:
                <input
                    type="text"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    required
                    className="form-control"
                />
            </label>
            <label className="form-label">
                Event Date:
                <input
                    type="date"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    required
                    className="form-control"
                />
            </label>
            {/* Display contacts in a multi-select */}
            <div>
                <label className="form-label">Select Contacts:</label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    multiple
                    value={selectedContacts}
                    onChange={(e) =>
                        setSelectedContacts(
                            Array.from(e.target.selectedOptions, (option) => option.value)
                        )
                    }
                >
                    {/* first check if there are any contacts */}
                    {contacts.length === 0 ? (
                        <option>No contacts found.</option>
                    ) : (
                        contacts.map((contact) => (
                            <option key={contact.id} value={contact.id}>
                                {contact.name} ({contact.email})
                            </option>
                        ))
                    )}

                </select>
                <div>
                    <label className="form-label">Email Message:</label>
                    <textarea className="form-control" value={emailMessage} onChange={(e) => setEmailMessage(e.target.value)}/>
                </div>
                <div>
                    <label className="form-label">Upload GIF:</label>
                    <input className="form-control" type="file" accept="image/gif" onChange={(e) => setGif(e.target.files[0])}/>
                </div>
            </div>
            <button type="submit" className="btn btn-primary mt-5">{event ? 'Update Event' : 'Create Event'}</button>
        </form>
    );
};

export default EventForm;
import React, { useState, useEffect } from 'react';
import { getAllEvents, deleteEvent } from '../services/EventService';
import { Modal, Button, Card, Container, Row, Col, Alert } from 'react-bootstrap';
import EventForm from '../components/forms/EventForm';

const EventList = () => {
    const [events, setEvents] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [refresh, setRefresh] = useState(false); // New state to handle refresh

    useEffect(() => {
        fetchEvents();
    }, [refresh]); // Add `refresh` as a dependency to trigger data reload

    const fetchEvents = async () => {
        try {
            const response = await getAllEvents();
            setEvents(response.data);
            console.log('events, ', response.data);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteEvent(id);
            setAlertMessage('Event deleted successfully!');
            setShowAlert(true);

            // Hide the alert after 3 seconds
            setTimeout(() => setShowAlert(false), 3000);

            // Trigger refresh after deletion
            setRefresh(!refresh);
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleEdit = (event) => {
        setSelectedEvent(event);
        setShowEditModal(true);
    };

    const handleCloseModal = () => {
        setShowEditModal(false);
    };

    // Callback function to handle refreshing after event edit or add
    const handleRefresh = () => {
        setShowEditModal(false);
        setRefresh(!refresh); // Trigger refresh of events
    };

    return (
        <Container fluid>
            {/* Success Alert */}
            {showAlert && (
                <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}

            <Row className="text-center mb-4">
                <Col>
                    <h1>Events</h1>
                </Col>
            </Row>
            <Row>
                {events.length === 0 ? (
                    <p>No events found.</p>
                ) : (
                    events.map((event, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card className="h-100">
                                <Card.Img
                                    variant="top"
                                    src={event.photoUrl}
                                    alt="event"
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                                <Card.Body>
                                    <Card.Title>{event.eventName}</Card.Title>
                                    <Card.Text>{event.eventDate}</Card.Text>
                                    <Card.Text>{event.emailMessage}</Card.Text>
                                    <div className="mb-3">
                                        <strong>Sending To:</strong>
                                        <ul className="list-unstyled mt-2">
                                            {event.contacts.map((contact, idx) => (
                                                <li key={idx}>{contact.name} ({contact.email})</li>
                                            ))}
                                        </ul>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between">
                                    <Button
                                        variant="warning"
                                        onClick={() => handleEdit(event)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleDelete(event.id)}
                                    >
                                        Delete
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>

            {/* Modal for Editing Event */}
            <Modal show={showEditModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedEvent && (
                        <EventForm event={selectedEvent} onSave={handleRefresh} /> // Pass callback to EventForm
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default EventList;

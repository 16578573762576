import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Events from './pages/Events';
import Contacts from './pages/Contacts';
import EventForm from './components/forms/EventForm';
import LoginForm from './components/forms/auth/LoginForm';
import RegisterForm from './components/forms/auth/RegisterForm';
import PrivateRoute from "./PrivateRoute";
import {jwtDecode}from 'jwt-decode';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
                if (decodedToken.exp < currentTime) {
                    console.log('Token expired');
                    localStorage.removeItem('jwt');
                    setIsLoggedIn(false);
                    navigate('/login');
                } else {
                    console.log('Token is valid');
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsLoggedIn(false);
            }
        } else {
            setIsLoggedIn(false);
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('jwt');
        setIsLoggedIn(false);
        navigate('/login');
    };

    return (
        <div className="App">
            <NavBar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
            <Routes>
                <Route path="/login" element={<LoginForm isLoggedIn={isLoggedIn} />} />
                <Route path="/register" element={<RegisterForm />} />
                <Route
                    path="/events"
                    element={<PrivateRoute element={Events} isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="/contacts"
                    element={<PrivateRoute element={Contacts} isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="/create-event"
                    element={<PrivateRoute element={EventForm} isLoggedIn={isLoggedIn} />}
                />
            </Routes>
        </div>
    );
};

export default App;

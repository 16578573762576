import React, { useState, useEffect } from 'react';
import { getUserContacts, deleteContact } from '../services/ContactService';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import ContactForm from '../components/forms/ContactForm';

const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchContacts();
    }, []);

    useEffect(() => {
        handleSearch(); // Update filtered contacts whenever searchQuery changes
    }, [searchQuery, contacts]);


    const fetchContacts = async () => {
        try {
            const response = await getUserContacts();
            setContacts(response.data);
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteContact(id);
            fetchContacts(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
    };

    const handleOpenModal = (contact = null) => {
        setSelectedContact(contact); // If contact is null, it means we're creating a new one
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedContact(null); // Clear selected contact after closing
        fetchContacts(); // Refresh the list
    };

    const handleSearch = () => {
        // Filter the contacts by name based on the search query
        const filtered = contacts.filter(contact =>
            contact.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredContacts(filtered);
    };

    const handlePrint = () => {
        window.print(); // Trigger the browser's print dialog
    };

    return (
        <div className="container mt-5">
            <h1 className="mt-5">Contacts</h1>
            <Button onClick={() => handleOpenModal(null)}>Create New Contact</Button>
            {/* Search Input */}
            <Form.Group className="mb-3 mt-3" controlId="search">
                <Form.Control
                    type="text"
                    placeholder="Search by contact name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
                />
            </Form.Group>
            <Button className="mb-3 ms-2" variant="dark" onClick={handlePrint}><i class="bi bi-printer"></i></Button>

            {/* Bootstrap Table for displaying contacts */}
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {filteredContacts.length === 0 ? (
                    <tr>
                        <td colSpan="5">No contacts found.</td>
                    </tr>
                ) : (
                    filteredContacts.map((contact, index) => (
                        <tr key={contact.id}>
                            <td>{index + 1}</td>
                            <td>{contact.name}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phoneNumber}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => handleOpenModal(contact)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDelete(contact.id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            {/* Modal for Creating and Editing Contacts */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedContact ? 'Edit Contact' : 'Create Contact'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm contact={selectedContact} onClose={handleCloseModal}/>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Contacts;